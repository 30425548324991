import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Modal } from "react-bootstrap";
import Head from "../Base/Head";
import { motion } from "framer-motion";
import Footer from "../Base/Footer";
import ApiService from "../services/ApiService";
import { toast } from "react-hot-toast";

const FaqItem = ({
  question,
  answer,
  faqIndex,
  activeFaqIndex,
  setActiveFaqIndex,
  pageContent,
}) => {
  const isFaqSelected = activeFaqIndex === faqIndex;
  const isFirstFaq = faqIndex === 0;

  const handleFaqClick = () => {
    setActiveFaqIndex(isFaqSelected ? null : faqIndex);
  };

  // Replace email addresses with mailto links
  const formattedAnswer = answer.replace(
    /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g,
    '<a href="mailto:$1">$1</a>'
  );

  return (
    <div
      className={`faq ${isFirstFaq ? "first" : ""}`}
      style={{
        "--theme-primary-color": pageContent.themePrimaryColor || "#015637",
      }}
    >
      <div
        onClick={handleFaqClick}
        className={`faq-head ${isFaqSelected ? "active" : ""}`}
      >
        <div>{question}</div>
      </div>
      <div className={`faq-body ${isFaqSelected ? "active" : ""}`}>
        <p dangerouslySetInnerHTML={{ __html: formattedAnswer }} />
      </div>
    </div>
  );
};

const RsvpField = ({
  className = "",
  fieldLabel,
  inputType = "text",
  value,
  setValue,
  required,
  onBlur = () => {},
}) => {
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    if (!required) {
      setIsValid(true);
    } else if (required && `${value}`.trim().length) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [value]);

  return (
    <div className={`rsvp-field ${className}`}>
      <div className="rsvp-field-label">
        {fieldLabel}
        {required ? <span className="asterisk">*</span> : null}
      </div>
      {inputType !== "textarea" ? (
        <input
          type={inputType}
          className="form-control"
          value={value}
          onChange={(event) => setValue(event.target.value)}
          onBlur={onBlur}
        />
      ) : (
        <textarea
          rows="2"
          className="form-control"
          value={value}
          onChange={(event) => setValue(event.target.value)}
          onBlur={onBlur}
        ></textarea>
      )}

      {/* {!isValid ? (
        <div className="required-field-msg">Required field</div>
      ) : null} */}
    </div>
  );
};

const EventDetails = ({
  motionTransisition,
  motionVariants,
  pageContent,
  handleRsvpBtnClicked,
  registrationClosed,
}) => {
  return (
    <motion.div
      className="ed-event-info"
      initial="initial"
      animate="animate"
      transition={motionTransisition}
      variants={motionVariants}
    >
      <div className="event-inner">
        <div className="event-image">
          <img className="img-fluid" src={pageContent.eventImages[0]} />
        </div>
        <div className="event-info-container">
          <div className="event-details-heading">EVENT DETAILS</div>
          <div className="event-info">
            <div className="event-info-text">
              <span className="start">Location:</span>
              <br />
              <p>{pageContent.eventLocationFull}</p>
            </div>
            <div className="event-info-text">
              <span className="start">Date:</span>
              {pageContent.eventDate}
            </div>
            <div className="event-info-text">
              <span className="start">Time:</span>
              {pageContent.eventTime}
            </div>
          </div>
          {registrationClosed ? (
            <div className="reg-closed-small">Registration is closed</div>
          ) : (
            <div
              className="rsvp-button"
              style={{
                "--theme-primary-color":
                  pageContent.themePrimaryColor || `#61999d`,
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.filter = "brightness(93%)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.filter = "brightness(100%)")
              }
              onClick={handleRsvpBtnClicked}
            >
              RSVP
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

const PartnerHeaderLogos = ({ headerLogos, className = "" }) => {
  const maxWidth = 156; //.ed-wrapper .header-bar .header-container .header-logos .header-logo
  const scaleMaxWidth = (scale) => {
    if (scale === 100) {
      return maxWidth;
    } else {
      // Calculate the width based on the scale and round to the nearest pixel
      return Math.round((scale / 100) * maxWidth);
    }
  };
  return (
    <>
      {/* <div className={`header-logo ${className}`}>
        <img
          className="img-fluid"
          src={
            process.env.PUBLIC_URL + "/images/dynamic/google-cloud-logo.png"
          }
          alt="Google Cloud"
        />
      </div> */}
      {headerLogos.map((hl, i) => {
        const style = { maxWidth: `${scaleMaxWidth(hl.scale)}px` };
        if (hl.yOffset && hl.yOffset !== 0) {
          style.transform = `translateY(${hl.yOffset}px)`;
        }
        return (
          <div key={i} className={`header-logo ${className}`} style={style}>
            <img className="img-fluid" src={hl.src} />
          </div>
        );
      })}
    </>
  );
};

export default function DynamicEvent({ event = {}, referral = {} }) {
  const [activeFaqIndex, setActiveFaqIndex] = useState(null);
  const [rsvpModal, setRsvpModal] = useState(false);
  const [modalShowClass, setModalShowClass] = useState("");
  const [isFooterVisible, setIsFooterVisible] = useState(true);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [company, setCompany] = useState("");
  const [dietaryRestrictions, setDietaryRestrictions] = useState("");
  const [marketingOptIn, setMarketingOptIn] = useState(false);
  const [dataAgreement, setDataAgreement] = useState(true);

  const [customAnswer, setCustomAnswer] = useState("");

  const [rsvpSubmitted, setRsvpSubmitted] = useState(false);
  const [apolloEmailChecked, setApolloEmailChecked] = useState(""); // the last email apollo checked

  const { pageContent, registrationClosed } = event;

  useEffect(() => {
    document.title = pageContent.heroHeading;
  }, []);

  const marketingOptInChange = (e) => {
    if (e.target.checked) {
      setMarketingOptIn(true);
    } else {
      setMarketingOptIn(false);
    }
  };

  const dataAgreementChange = (e) => {
    if (e.target.checked) {
      setDataAgreement(true);
    } else {
      setDataAgreement(false);
    }
  };

  const titleFormattedHeroHeading = pageContent.heroHeading
    .replace(/\\n/g, " - ") // Replace escaped newlines
    .replace(/\n/g, " - "); // Replace unescaped newlines

  const head = {
    title: titleFormattedHeroHeading,
    description: pageContent.heroSubHeading,
    url: `https://events.landmarkventures.com/${event.pageUrl}`,
    image: "",
  };

  async function handleSetRsvpModal(show) {
    if (show) {
      setIsFooterVisible(false);
      setRsvpModal(true);
    } else {
      setIsFooterVisible(true);
      setRsvpModal(false);
    }
  }

  async function handleRsvpBtnClicked() {
    setIsFooterVisible(false);
    setRsvpModal(true);
    // setTimeout(() => {
    // }, 0);
  }

  const subtleBounceTransition = {
    type: "spring",
    stiffness: 96, // Lower stiffness for a more subtle bounce
    damping: 28, // Higher damping to reduce the bounce intensity
    duration: 2, // Increase the duration for a slower overall effect
  };

  const scaleInTransition = {
    type: "spring",
    stiffness: 200, // Lower stiffness for a more subtle bounce
    damping: 100, // Higher damping to reduce the bounce intensity
    duration: 0.7, // Increase the duration for a slower overall effect
  };

  const headerVariants = {
    initial: { y: -140, opacity: 0 }, // Start off-screen to the left with no opacity and no scale
    animate: { y: 0, opacity: 1 }, // Move to the final position, full opacity, and scale
  };

  const infoVariants = {
    initial: { x: -700, opacity: 0 }, // Start off-screen to the left with no opacity and no scale
    animate: { x: 0, opacity: 1 }, // Move to the final position, full opacity, and scale
  };

  const imageVariants = {
    initial: { x: 700, opacity: 0 }, // Start off-screen to the left with no opacity and no scale
    animate: { x: 0, opacity: 1 }, // Move to the final position, full opacity, and scale
  };

  const scaleVariants = {
    initial: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
  };

  let formValid = false;
  let formErrorMsg = ``;
  // validate form
  const validateForm = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!email.length || !emailRegex.test(email)) {
      formErrorMsg = "Please enter a valid email address";
      return false;
    }
    if (!firstName.length) {
      formErrorMsg = "Please enter your first name";
      return false;
    }
    if (!lastName.length) {
      formErrorMsg = "Please enter your last name";
      return false;
    }
    if (!jobTitle.length) {
      formErrorMsg = "Please enter your job title";
      return false;
    }
    if (!company.length) {
      formErrorMsg = "Please enter your company";
      return false;
    }

    if (pageContent.showCustomInput && pageContent.customInputRequired) {
      if (!customAnswer.length) {
        formErrorMsg = `Please answer the ${pageContent.customInputLabel} question`;
        return false;
      }
    }

    // if (!marketingOptIn) {
    //   formErrorMsg =
    //     "You must agree to receive news from Google and its Partners";
    //   return false;
    // }
    // if (!dataAgreement) {
    //   formErrorMsg = "You must confirm you understand the data privacy policy";
    //   return false;
    // }
    return true;
  };
  formValid = validateForm();
  const submitDisabledClass = formValid ? "" : "disabled";

  async function handleEmailBlur() {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!email.length || !emailRegex.test(email)) {
      // invalid email address
      return false;
    }
    // email valid

    // email already checked by apollo
    if (
      apolloEmailChecked.toLowerCase().trim() === email.toLowerCase().trim()
    ) {
      return;
    }

    // autofill is enabled for this event
    if (event.apolloAutofill) {
      // search email on apollo
      try {
        setApolloEmailChecked(email);
        const api = new ApiService();
        const response = await api.get(
          `landmark/apollo/searchByEmail/${email.trim()}`
        );
        // console.log(`handleEmailBlur() - response`, response);
        if (response.success && response.data) {
          const { first_name, last_name, organization_name, title } =
            response.data;
          // autofill form
          setCompany(organization_name);
          setJobTitle(title);
          setFirstName(first_name);
          setLastName(last_name);
        } else {
          throw "Email search error";
        }
      } catch (error) {
        console.error("handleEmailBlur() - Error searching email", error);
      }
    }
  }

  async function submitRsvp() {
    if (!formValid) {
      console.log(`submitRsvp() - form is invalid`);
      return;
    }
    const form = {
      eventId: event.eventId,
      pageUrl: event.pageUrl,
      firstName,
      lastName,
      company,
      jobTitle,
      email,
      dietaryRestrictions,
      marketingOptIn,
    };

    if (pageContent.showCustomInput) {
      form.customQuestion = pageContent.customInputLabel;
      form.customAnswer = customAnswer;
    }

    if (referral && referral.url && referral.name) {
      form.referredByUrl = referral.url;
      form.referredByName = referral.name;
    }

    console.log(`submitRsvp()`, form);
    try {
      const api = new ApiService();
      const response = await api.post(`landmark/dynamicEvents/submitRsvp`, {
        form,
      });
      console.log(`submitRsvp() - response`, response);
      if (response.success) {
        setTimeout(() => {
          setRsvpSubmitted(true);
        }, 50);
      } else {
        throw "Backend error";
      }
    } catch (error) {
      console.error("submitRsvp() - Error submitting form", error);
      toast.error("An error occurred. Please try again later.");
    }
  }

  let formattedHeroHeading = pageContent.heroHeading
    .replace(/\\n/g, "<br /><span>") // Replace escaped newlines
    .replace(/\n/g, "<br /><span>"); // Replace unescaped newlines
  // Add closing </span> only if a <span> was added
  if (formattedHeroHeading.includes("<br /><span>")) {
    formattedHeroHeading += "</span>";
  }

  return (
    <>
      <Head head={head} noTwitterCard={true} />
      <div className="ed-wrapper">
        <div className="ed-main">
          <motion.div
            className="header-bar"
            initial="initial"
            animate="animate"
            transition={subtleBounceTransition}
            variants={headerVariants}
          >
            <div className="auto__container">
              <div className="header-container">
                <div className="header-logos left">
                  <PartnerHeaderLogos headerLogos={pageContent.headerLogos} />
                  <div className="header-logo lg mob-only">
                    <img
                      className="img-fluid "
                      src={
                        process.env.PUBLIC_URL +
                        "/images/dynamic/landmark-logo-alt.png"
                      }
                      alt="Landmark Ventures"
                    />
                  </div>
                </div>
                <div className="header-logos right">
                  {/* <PartnerHeaderLogos headerLogos={pageContent.headerLogos} /> */}
                  <div className="header-logo lg">
                    <img
                      className="img-fluid"
                      src={
                        process.env.PUBLIC_URL +
                        "/images/dynamic/landmark-logo-alt.png"
                      }
                      alt="Landmark Ventures"
                    />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>

          <div className="auto__container">
            <div className="ed-block-container">
              <div className="ed-vertical-flex">
                <div className="ed-left-container">
                  <div className="ed-hero">
                    <motion.div
                      className="hero-info-container"
                      initial="initial"
                      animate="animate"
                      transition={subtleBounceTransition}
                      variants={infoVariants}
                    >
                      <div className="hero-location">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/dynamic/location-pointer.svg"
                          }
                          loading="lazy"
                          alt=""
                        />
                        <div>{pageContent.eventLocationShort}</div>
                      </div>
                      <p
                        className="hero-heading section-heading"
                        dangerouslySetInnerHTML={{
                          __html: formattedHeroHeading,
                        }}
                      />
                      {registrationClosed ? (
                        <div className="reg-closed-big-container">
                          <div className="reg-closed-divider top"></div>
                          <div className="reg-closed-big">
                            Registration is now closed. Thank you for your
                            interest!
                          </div>
                          <div className="reg-closed-divider bottom"></div>
                        </div>
                      ) : null}
                      <div className="hero-subheading">
                        {pageContent.heroSubHeading}
                      </div>
                      <div className="hero-text">{pageContent.heroText}</div>
                      {pageContent.expectBullets &&
                      pageContent.expectBullets.length ? (
                        <ul className="expect-bullets">
                          {pageContent.expectBullets.map((b, i) => (
                            <li key={i}>{b}</li>
                          ))}
                        </ul>
                      ) : null}
                      {registrationClosed ? (
                        <div className="reg-closed-small">
                          Registration is closed
                        </div>
                      ) : (
                        <>
                          <div
                            className="rsvp-button"
                            style={{
                              "--theme-primary-color":
                                pageContent.themePrimaryColor || `#61999d`,
                            }}
                            onMouseEnter={(e) =>
                              (e.currentTarget.style.filter = "brightness(93%)")
                            }
                            onMouseLeave={(e) =>
                              (e.currentTarget.style.filter =
                                "brightness(100%)")
                            }
                            onClick={handleRsvpBtnClicked}
                          >
                            RSVP
                          </div>
                          <div className="expect-disclaimer">
                            Upon registering, our team will follow up with a
                            formal confirmation and calendar hold with more
                            details.
                          </div>
                        </>
                      )}
                    </motion.div>

                    {/* <motion.div
                className="hero-image-container"
                initial="initial"
                animate="animate"
                transition={subtleBounceTransition}
                variants={imageVariants}
              >
                <div className="hero-image">
                  <img className="img-fluid" src={pageContent.heroImages[0]} />
                </div>
              </motion.div> */}
                  </div>
                  {/* <div className="horizontal-rule hero"></div> */}
                  <div className="w-100 mob-only">
                    <EventDetails
                      motionTransisition={subtleBounceTransition}
                      motionVariants={imageVariants}
                      pageContent={pageContent}
                      handleRsvpBtnClicked={handleRsvpBtnClicked}
                      registrationClosed={registrationClosed}
                    />
                  </div>

                  <motion.div
                    className="ed-expect"
                    initial="initial"
                    animate="animate"
                    transition={{ ...subtleBounceTransition, delay: 0.2 }}
                    variants={infoVariants}
                  >
                    {/* <div className="expect-title section-heading sm">
                      What to Expect
                    </div>
                    <div className="expect-text">{pageContent.expectText}</div>
                    {pageContent.expectBullets &&
                    pageContent.expectBullets.length ? (
                      <ul className="expect-bullets">
                        {pageContent.expectBullets.map((b, i) => (
                          <li key={i}>{b}</li>
                        ))}
                      </ul>
                    ) : null}
                    <div className="expect-disclaimer">
                      Space is limited. Please register ASAP and we will send
                      along a calendar invite!
                    </div> */}

                    <div className="horizontal-rule"></div>

                    {pageContent.faqs && pageContent.faqs.length ? (
                      <div className="ed-faqs">
                        <div className="ed-faq-title section-heading sm">
                          FAQs
                        </div>
                        {pageContent.faqs.map((faq, i) => (
                          <FaqItem
                            key={i}
                            question={faq.question}
                            answer={faq.answer}
                            faqIndex={i}
                            activeFaqIndex={activeFaqIndex}
                            setActiveFaqIndex={setActiveFaqIndex}
                            pageContent={pageContent}
                          />
                        ))}
                      </div>
                    ) : null}
                  </motion.div>

                  {/* <div className="footer-spacer"></div> */}
                </div>
                <div className="ed-right-rail">
                  <div className="sticky-content">
                    <EventDetails
                      motionTransisition={subtleBounceTransition}
                      motionVariants={imageVariants}
                      pageContent={pageContent}
                      handleRsvpBtnClicked={handleRsvpBtnClicked}
                      registrationClosed={registrationClosed}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {pageContent.footerImages && pageContent.footerImages.length ? (
            <div className="auto__container">
              <div className="footer-image-outer">
                <div className="footer-image">
                  <img
                    className="img-fluid"
                    src={pageContent.footerImages[0]}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div className="footer-spacer"></div>

          {/* optional hidelandmark footer */}
          {!pageContent.hideLandmarkFooter ? (
            <div className="ed-footer">
              <Footer alternate={true} />
            </div>
          ) : null}
        </div>
      </div>
      <Modal
        className={`modal fade rsvp-modal ${modalShowClass}`}
        centered
        show={rsvpModal}
        onHide={() => {
          setModalShowClass("scale-out");
          handleSetRsvpModal(false);
          // setTimeout(() => , 300);
        }}
        onShow={() => {
          setModalShowClass("scale-in");
        }}
        dialogClassName="scale-in"
      >
        {rsvpSubmitted ? (
          <>
            <div
              className="rsvp-modal-close"
              onClick={() => handleSetRsvpModal(false)}
            >
              X
            </div>
            <motion.div
              initial="initial"
              animate="animate"
              transition={{ duration: 0.4, ease: [0.25, 0.1, 0.25, 1] }}
              variants={scaleVariants}
            >
              <div className="rsvp-modal-title sm">Your RSVP is confirmed</div>
              <div className="rsvp-submitted-text">
                Thanks for your interest in this event! Keep your eyes peeled
                for a confirmation and calendar invite.
              </div>
            </motion.div>{" "}
          </>
        ) : (
          <>
            <div className="rsvp-modal-title">Register for the event</div>
            <div
              className="rsvp-modal-close"
              onClick={() => handleSetRsvpModal(false)}
            >
              X
            </div>
            <div className="fields-column">
              <RsvpField
                className="first"
                fieldLabel="Email"
                inputType="email"
                value={email}
                setValue={setEmail}
                required={true}
                onBlur={handleEmailBlur}
              />
              <div className="fields-row">
                <RsvpField
                  fieldLabel="First Name"
                  value={firstName}
                  setValue={setFirstName}
                  required={true}
                  halfWidth={true}
                />
                <RsvpField
                  fieldLabel="Last Name"
                  value={lastName}
                  setValue={setLastName}
                  required={true}
                  halfWidth={true}
                />
              </div>
              <RsvpField
                fieldLabel="Job Title"
                value={jobTitle}
                setValue={setJobTitle}
                required={true}
              />
              <RsvpField
                className={`${
                  !pageContent.showCustomInput && pageContent.hideDietaryInput
                    ? "last"
                    : ""
                }`}
                fieldLabel="Company Name"
                value={company}
                setValue={setCompany}
                required={true}
              />
              {pageContent.showCustomInput ? (
                <RsvpField
                  className={`${pageContent.hideDietaryInput ? "last" : ""}`}
                  fieldLabel={pageContent.customInputLabel}
                  value={customAnswer}
                  setValue={setCustomAnswer}
                  required={pageContent.customInputRequired}
                />
              ) : null}
              {pageContent.hideDietaryInput ? null : (
                <RsvpField
                  className="last"
                  fieldLabel="Dietary Restrictions"
                  inputType="textarea"
                  value={dietaryRestrictions}
                  setValue={setDietaryRestrictions}
                />
              )}

              {event.googlePolicyOptions ? (
                <label
                  htmlFor="marketingOptIn"
                  className="check rsvp-checkbox-container"
                >
                  <div className="check__box">
                    <input
                      type="checkbox"
                      id="marketingOptIn"
                      checked={marketingOptIn}
                      onChange={marketingOptInChange}
                    />
                    <span>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/tick-white.svg"
                        }
                        alt="icon"
                      />
                    </span>
                  </div>
                  <div className="check-label">
                    Sign me up to receive news, product updates, event
                    information and special offers from Google Cloud and its
                    Partners. (optional)
                  </div>
                </label>
              ) : null}
            </div>

            <div
              className="register-button"
              onClick={() => submitRsvp()}
              style={{
                "--theme-primary-color":
                  pageContent.themePrimaryColor || `#61999d`,
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.filter = "brightness(93%)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.filter = "brightness(100%)")
              }
            >
              Register
            </div>
            {!formValid ? (
              <div className="required-field-msg bottom">{formErrorMsg}</div>
            ) : (
              <div className="required-field-msg bottom"></div>
            )}
            {event.googlePolicyOptions ? (
              <label
                htmlFor="dataAgreement"
                className="check rsvp-checkbox-container"
              >
                <div className="check-label legal-notice">
                  I understand my personal data will be processed by Google in
                  accordance with{" "}
                  <a
                    className="inline-link"
                    href="https://policies.google.com/privacy"
                    target="_blank"
                  >
                    Google’s Privacy Policy
                  </a>{" "}
                  and may be shared with members of{" "}
                  <a
                    className="inline-link"
                    href="https://cloud.google.com/partners"
                    target="_blank"
                  >
                    Google Cloud’s Partner Program
                  </a>
                  , who may send you marketing communications in accordance with
                  the partner's privacy policy.
                </div>
              </label>
            ) : null}
          </>
        )}
      </Modal>
    </>
  );
}
