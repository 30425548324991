import React from "react";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
export default function Intro() {
  return (
    <>
      <section className="hero">
        <div className="hero__bg">
          <img
            src={process.env.PUBLIC_URL + "/images/unga/hero.jpg"}
            alt="hero"
          />
        </div>
        <div className="auto__container">
          <div className="hero__inner">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div
                    data-aos="fade-up"
                    data-aos-delay={400}
                    className="aos-init aos-animate"
                  >
                    <motion.div
                      animate={{
                        scale: [0, 1.1, 1],
                        opacity: [0, 1],
                      }}
                    >
                      <div className="hero-container">
                        <div className="hero-logos">
                          <div className="lm-logo">
                            <img
                              className="img-fluid"
                              src={
                                process.env.PUBLIC_URL +
                                "/images/unga/landmark-logo.png"
                              }
                            />
                          </div>
                          <div className="un-logo">
                            <img
                              className="img-fluid"
                              src={
                                process.env.PUBLIC_URL +
                                "/images/unga/un-logo.png"
                              }
                            />
                          </div>
                          <div className="sis-logo">
                            <img
                              className="img-fluid"
                              src={
                                process.env.PUBLIC_URL +
                                "/images/unga/sis-logo.png"
                              }
                            />
                          </div>
                        </div>
                        <h1>
                          <span className="text-white league-spartan-font bold">
                            JOIN SIS @ UN GENERAL ASSEMBLY
                            <br />& CLIMATE WEEK 2024
                          </span>
                        </h1>
                        <div
                          data-aos="fade-up"
                          data-aos-delay={800}
                          className="aos-init aos-animate"
                        >
                          <div className="hero-btns">
                            <div className="hero-btn">
                              <a
                                href="#apply"
                                type="button"
                                className="pink-btn no-grad simple"
                              >
                                APPLY
                              </a>
                            </div>
                            {/* <div className="hero-btn">
                              <a
                                href="#join"
                                type="button"
                                className="button btn-std pink"
                              >
                                CO-HOST AN EVENT
                              </a>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                </div>
                {/* <h1>
                      <span className="text-white">
                        Join Landmark Ventures @ CES 2025
                      </span>
                    </h1>
                    <p>
                      <span className="text-light">
                        We are pleased to host our annual LMV @ CES event
                        series, custom built for our robust network of Fortune
                        500 CMOs, Chief Digital Officer, Venture Capitalists,
                        Entrepreneurs, and Brand Executives across the digital
                        marketing, eCommerce, advertising, sports, social
                        impact, Web3, and media ecosystems.
                      </span>
                    </p>
                  </div>
                  {" "} */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container narrower" style={{ marginTop: "20px" }}>
        <div className="cap-width">
          <div className="row hero-row-lower">
            <div className="col-12 col-md-6">
              <motion.div
                animate={{
                  x: [-200, 0],
                  opacity: [0, 1],
                }}
                transition={{ duration: 1.2, ease: "easeOut" }}
              >
                <div className="header-img">
                  <img
                    className="img-fluid"
                    src={
                      process.env.PUBLIC_URL + "/images/unga/header-social.png"
                    }
                    alt="landmark-events"
                  />
                </div>
              </motion.div>
            </div>
            <div className="col-12 col-md-6">
              <motion.div
                animate={{
                  x: [50, 0],
                  opacity: [0, 1],
                }}
                transition={{ duration: 1.2, ease: "easeOut" }}
              >
                <div className="header-text-container">
                  <div className="header-text">
                    Social Innovation Summit, along with our partners, are
                    delighted to host a series of VIP events for our network of
                    corporate responsibility executives, philanthropists,
                    investors, nonprofit leaders, policymakers, celebrity
                    activists, and social entrepreneurs across the global impact
                    ecosystem during UN General Assembly week 2024!
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
