import React from "react";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
export default function Intro() {
  return (
    <>
      <section className="hero">
        <div className="hero__bg">
          <img
            src={process.env.PUBLIC_URL + "/images/ces/hero.jpg"}
            alt="hero"
          />
        </div>
        <div className="auto__container">
          <div className="hero__inner">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div
                    data-aos="fade-up"
                    data-aos-delay={400}
                    className="aos-init aos-animate"
                  >
                    <motion.div
                      animate={{
                        scale: [0, 1.1, 1],
                        opacity: [0, 1],
                      }}
                    >
                      <div className="hero-container">
                        <h1>
                          <span className="text-white">
                            WELCOME TO LANDMARK
                            <br /> ISRAEL DEAL PORTAL
                          </span>
                        </h1>
                        <div
                          data-aos="fade-up"
                          data-aos-delay={800}
                          className="aos-init aos-animate"
                        >
                          <div className="hero-btns">
                            <div className="hero-btn">
                              <a
                                href="#portfolio"
                                type="button"
                                className="button btn-std pink"
                              >
                                VIEW PORTFOLIO
                              </a>
                            </div>
                            {/* <div className="hero-btn">
                              <a
                                href="#join"
                                type="button"
                                className="button btn-std pink"
                              >
                                CO-HOST AN EVENT
                              </a>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                </div>
                {/* <h1>
                      <span className="text-white">
                        Join Landmark Ventures @ CES 2025
                      </span>
                    </h1>
                    <p>
                      <span className="text-light">
                        We are pleased to host our annual LMV @ CES event
                        series, custom built for our robust network of Fortune
                        500 CMOs, Chief Digital Officer, Venture Capitalists,
                        Entrepreneurs, and Brand Executives across the digital
                        marketing, eCommerce, advertising, sports, social
                        impact, Web3, and media ecosystems.
                      </span>
                    </p>
                  </div>
                  {" "} */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container narrower">
        <div className="cap-width">
          <div className="row hero-row-lower">
            {/* <div className="col-12 col-md-6">
              <motion.div
                animate={{
                  x: [-200, 0],
                  opacity: [0, 1],
                }}
                transition={{ duration: 1.2, ease: "easeOut" }}
              >
                <div className="header-img">
                  <img
                    className="img-fluid"
                    src={process.env.PUBLIC_URL + "/images/ces/header-img.png"}
                    alt="landmark-events"
                  />
                </div>
              </motion.div>
            </div> */}
            <div className="col-12 col-md-12">
              <motion.div
                animate={{
                  x: [50, 0],
                  opacity: [0, 1],
                }}
                transition={{ duration: 1.2, ease: "easeOut" }}
              >
                <div className="header-text-container">
                  <div className="header-text text-center">
                    Landmark maintains deep roots supporting the tech ecosystem
                    in Israel, with our founding team hailing from the country,
                    longstanding offices in Tel Aviv and Raanana, and a rich
                    history of engagement with the Israel startup and investment
                    ecosystem, boasting over 1,000 successful clients,
                    investors, and partners in Israel.
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
